<template>
    <v-dialog
    :retain-focus="false"
    no-click-animation
    :width="1200"
    transition="fade-transition"
    scrollable
    persistent
    @keydown.esc="$emit('cancel')"
    v-model="dialog"
    >
        <v-card tile>
            <v-toolbar dark flat class="bg-gradient" height="64">
                <v-btn x-large depressed color="transparent" class="ml-2" @click="$emit('cancel')"><v-icon dark small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed outlined class="mr-3" @click="dialogConfirm = true">Connecter l'application</v-btn>
            </v-toolbar>
            <v-card-text class="py-4" v-html="appDetails.descriptif">
            </v-card-text>
        </v-card>
        <dialogConfirmConnexionApp @cancel="dialogConfirm = false" :dialog="dialogConfirm" :appDetails="appDetails" @save="connecterApp()"/>
    </v-dialog>
</template>


<script>
import GenericDataService from '../../../services/GenericDataService';
import dialogConfirmConnexionApp from './dialogConfirmConnexionApp.vue';

export default {
    name: 'appListDialog',
    props: {
        dialog: Boolean,
        appDetails: Object
    },
    components: {dialogConfirmConnexionApp},
    data() {
        return {
            dialog: false,
            dialogConfirm: false
        }
    },
    methods: {
        connecterApp() {
            this.dialogConfirm = false;

            let payload = {
                hook: appDetails.hook,
                account_id : this.$store.state.currentAccountId,
                app_ref: appDetails.app_ref
            }
            // Appel API pour connecter l'application

            // Enregistrer la réponse en bdd
            GenericDataService.postData('/externalapp/saveConnectionApp', payload).then((response) => {
                this.$emit('cancel');
            })
            this.$emit('cancel');
        }
    }
}
</script>

<style lang="scss">
.logoApp {
    margin: 0;
    border-radius: 100px;
    width: 80px;
}

.bulletPointHeader {
    margin-bottom: 4px !important;
}

.headerDescriptif {
    display: flex;
    align-items: center;
}
</style>