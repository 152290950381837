<template>
    <div>
        <v-container fluid class="pa-6 ">
            <p>Intégration liste</p>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'IntegrationList',
    data() {
        return {
            integrations: []
        }
    }
}

</script>