<template>
    <div>
        <v-container fluid class="pa-6 ">
            <p>Webhook liste</p>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'WebhookList',
    data() {
        return {
            webhooks: []
        }
    }
}
</script>