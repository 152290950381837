<template>
    <div>
        <v-container fluid class="pa-6 ">
            <p>Api liste</p>
        </v-container>
    </div>
</template>

<script>

export default {
    name: 'ApiList',
    data() {
        return {
            apis: []
        }
    }
}
</script>