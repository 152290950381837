<template>
    <v-dialog
    :retain-focus="false"
    no-click-animation
    :width="600"
    transition="fade-transition"
    scrollable
    persistent
    @keydown.esc="$emit('cancel')"
    v-model="dialog"
    >
        <v-card tile>
            <v-toolbar dark flat class="bg-gradient" height="64">
                <v-btn x-large depressed color="transparent" class="ml-2" @click="$emit('cancel')"><v-icon dark small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn depressed outlined class="mr-3" @click="">Connecter</v-btn>
            </v-toolbar>
            <v-card-text class="py-4">
                <h5>Connecter {{ appDetails.name }} ?</h5>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'dialogConfirmConnexionApp',
    props: {
        dialog: Boolean,
        appDetails: Object
    },
    data() {
        return {
            dialog: false
        }
    },
}
</script>